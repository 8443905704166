import { PartnersType } from "../../@types/globalTypes";
import {
    Container
} from './styles';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    item: PartnersType
    onDisable: ()=> void;
};

export function CardPartnersAdmin({ item, onDisable, ...rest }: Props) {

    return (
        <Container>
            <button {...rest}>
                <img src={item.partner.logo} alt={item.partner.name} />
                <h3>{item.partner.name}</h3>
            </button>
            <button className='danger' onClick={onDisable}>
                {item.isActive ? 'Desabilitar parceiro' : 'Habilitar parceiro'}
            </button>
        </Container>
    )
}
