import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  min-width: 20rem;
  grid-template-columns: 20% 80%;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  height: 3rem;
  margin-top: 1rem;
  transition: 0.3s;
  &:hover {
    border-color: #757575;
  }
  input {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const IconInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;