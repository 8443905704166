import { Input } from "../../components/Input";
import { Container, Title } from "./styles";
import emailSvg from "../../assets/email.svg";
import passwordSvg from "../../assets/password.svg";
import { FormEvent, useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import logo from '../../assets/logo.png';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { signin, user } = useAuth();

    async function handleLogin(e: FormEvent) {
        e.preventDefault();
        setLoading(true);
        await signin(email, password);
        setLoading(false);
    }

    useEffect(() => {
        if (user) {
            history.push('/admin/dashboard');
            toast('Bem vindo a área Administrativa')
        }
    }, [user, history])

    return (
        <Container>
            <img src={logo} alt="acaiteria" />
            <Title>
                Acessar backoffice
            </Title>
            <form onSubmit={handleLogin}>
                <Input
                    icon={emailSvg}
                    value={email}
                    isRequired={true}
                    setValue={setEmail}
                    type="email"
                    placeholder="E-mail"
                />
                <Input
                    icon={passwordSvg}
                    isRequired={true}
                    value={password}
                    setValue={setPassword}
                    placeholder="Senha"
                    type="password"
                />
                <button type="submit" disabled={loading}>
                    {loading ? "CARREGANDO..." : "ACESSAR"}
                </button>
            </form>
        </Container>
    )
}
