import styled from 'styled-components';

export const Container = styled.div`
    overflow-y: scroll;
    min-height: 100%;
    max-height: 80vh;
`;

export const Title = styled.h2`
    font-weight: 500;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-right: 1rem;

    >button[type='submit']{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        padding: .8rem 1.8rem;
        border-radius: 0.3rem;
        color: white;
        background: var(--green);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;

        &:hover{
            box-shadow: 0px 15px 20px rgba(96, 213, 43, 0.3);
            transform: translateY(-3px);
        }
    }
`;

export const File = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

export const FileContainer = styled.div`
    margin-bottom: 1rem;
    >label{
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        font-size: 14px;
        padding: 10px 12px;
        background-color: #4245a8;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }
    >span{
        margin-left: 1rem;
        font-size: 0.9rem;
        color: var(--base);
    }
`;

export const Loader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
