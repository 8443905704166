import {
    Container,
    Content,
    Logo,
    ExitContainer,
    ButtonExit
} from './styles'
import logo from '../../assets/logo.png';
import { useAuth } from '../../context/AuthContext';
import { IoExitOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom';

export default function Header() {
    const { user, logout } = useAuth();
    return (
        <Container>
            <Content>
                <Link to="/">
                    <Logo src={logo} alt="acaiteria" />
                </Link>
                <ExitContainer>
                    <p>{user?.email}</p>
                    <ButtonExit title="Desconectar" onClick={logout}>
                        <IoExitOutline size='1.5rem' />
                    </ButtonExit>
                </ExitContainer>
            </Content>
        </Container>
    )
}
