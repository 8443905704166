import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 6rem;
`;

export const Content = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    >button{
        background: transparent;
        border: 0;
    }
`;

export const Logo = styled.img`
    width: 7rem;
`;

export const ExitContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonExit = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: .3rem;
    border: 0;
    width: 45px;
    height: 45px;
    margin-left: 1rem;
    transition: .4s;

    &:hover{
        color: red;
        background: var(--base-100);
    }
`;