import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1260px;
    height: 93vh;
    margin: 0 auto;
    padding: 1rem;

    @media (max-width: 430px){
        p{
            display: none;
        }
    }
`;

export const Title = styled.h1`
    font-weight: 200;
    color: var(--base);
    display: block;
`;

export const SubHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @media (max-width: 540px)
    {
        flex-direction: column;
    }
    
    >div{
        display: flex;
    }
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
`;

export const NotItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 1rem;
`;

export const NotItemsImage = styled.img`
    margin-top: 1rem;
    width: 30rem;

    @media (max-width: 540px)
    {
        width: 100%;
    }
`;

export const NotItemsTitle = styled.h1`
    color: var(--base);
    font-weight: 500;
`;

export const ButtonAdd = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    padding: .8rem 1.8rem;
    border-radius: 0.3rem;
    color: white;
    margin-left: 1rem;
    background: var(--green);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;

    &:hover{
        filter: brightness(.9);
    }

    &:first-child{
        background: var(--secondary);
    }
`;