import { FormEvent, useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { usePartners } from '../../context/PartnersContext';
import { firestore, storage } from '../../services/firebase';
import { Container, FileContainer, Form, Loader, Title, File } from './styles';

type Props = {
    isOpen: boolean;
    onRequestClose: () => void;
};


export function BannerModal({ isOpen, onRequestClose }: Props) {
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File>({} as File);
    const [imageURL, setImageUrl] = useState<string>('');
    const { banner } = usePartners();

    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        const filesList = e.target.files;
        if (!filesList) return;
        setFile(filesList[0])
        setImageUrl(URL.createObjectURL(filesList[0]))
    }

    async function handleUpload(e: FormEvent) {
        e.preventDefault();
        setLoading(true);
        if (file.name) {
            const ref = storage.ref(`/banner/${file.name}`);
            const uploadTask = ref.put(file);
            uploadTask.on("state_changed", console.log, console.error, () => {
                ref
                    .getDownloadURL()
                    .then((url) => {
                        setFile({} as File);
                        firestore.collection('banners').doc(process.env.REACT_APP_BANNER).update({
                            bannerLink: url
                        }).then(() => {
                            toast.success('Banner atualizado com sucesso');
                            onRequestClose();
                        }).catch(() => {
                            toast.error('Houve um erro ao tentar salvar sua imagem');
                        }).finally(() => setLoading(false))
                    });
            });
        }
        else {
            alert('Você precisa selecionar um banner');
        }
    }

    useEffect(() => {
        setFile({} as File);
        setImageUrl('');

    }, [onRequestClose])

    return (
        <Modal
            isOpen={isOpen}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button type="button" disabled={loading} onClick={onRequestClose} className="react-modal-close">
                <IoCloseOutline size="1.5rem" />
            </button>
            <Container>
                {loading
                    ? (
                        <Loader>
                            <h2>Cadastrando todos parceiros...</h2>
                            <p>Por favor, aguarde</p>
                            <div className="lds-ellipsis"><div></div><div></div><div></div></div>
                        </Loader>
                    )
                    : <>
                        <Title>Banner</Title>
                        <img src={imageURL || banner} alt="imagem do Banner" width="98%" />
                        <Form onSubmit={handleUpload}>
                            <FileContainer>
                                <File type="file" accept=".png" id="file-input" onChange={handleFileChange} />
                                <label htmlFor="file-input">Upload Logo</label>
                                <span>{file?.name}</span>
                                <p>Recomendado: 667x268</p>
                            </FileContainer>

                            <button type="submit" disabled={loading}>{loading ? "Carregando..." : "Alterar Banner"}</button>
                        </Form>
                    </>
                }
            </Container>
        </Modal>
    )
}
