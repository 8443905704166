import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { auth } from '../services/firebase';

type AuthContextProviderProps = {
    children: ReactNode;
}

type User = {
    uid: string;
    email: string;
}

type AuthContextType = {
    user: User | undefined;
    signin: (email: string, password: string) => Promise<void>;
    logout: () => void;
}

const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({ children }: AuthContextProviderProps) {
    const [user, setUser] = useState<User>();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const { uid, email } = user

                setUser({
                    uid,
                    email: email || ''
                })
            }
        })

        return () => {
            unsubscribe();
        }
    }, []);

    async function signin(email: string, password: string) {
        try {
            const data = await auth.signInWithEmailAndPassword(email, password);
            setUser({
                uid: data.user?.uid || '',
                email: data.user?.email || ''
            })
        } catch (error) {
            switch (error.code) {
                case "auth/wrong-password":
                    toast.error("A senha informada está incorreta");
                    return;
                case "auth/invalid-email":
                    toast.error("O email informado é invalido");
                    return;
                case "auth/user-not-found":
                    toast.error("Email não cadastrado");
                    return;
                default:
                    toast.error("Verifique seus dados");
                    return;
            }
        }
    }

    function logout() {
        auth.signOut();
        setUser(undefined)
        toast.info('Você foi desconectado')
    }

    return (
        <AuthContext.Provider value={{ user, signin, logout }}>
            {children}
        </AuthContext.Provider>
    );
}
export function useAuth() {
    return useContext(AuthContext);
}