import { Container, IconInput } from "./styles";

type Props = {
    icon?: string;
    placeholder: string;
    value: string;
    type: string;
    setValue: (value: string) => void;
    isRequired: boolean;
}

export function Input({
    icon,
    placeholder,
    value,
    setValue,
    type = "text",
    isRequired = false,
}: Props) {
    return (
        <Container>
            {icon && <IconInput>
                <img src={icon} alt="" />
            </IconInput>}
            <input
                value={value}
                type={type}
                required={isRequired}
                placeholder={placeholder}
                onChange={(e) => setValue(e.target.value)}
            />
        </Container>
    );
}