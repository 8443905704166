import firebase from "firebase/app";

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_API_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_API_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_API_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_API_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_API_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_API_MEASUREMENTID,
};

firebase.initializeApp(config);

const auth = firebase.auth();

const firestore = firebase.firestore();

const storage = firebase.storage();

export { firebase, auth, firestore, storage }