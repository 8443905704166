import styled from 'styled-components';

export const Container = styled.div`
    overflow-y: scroll;
    min-height: 100%;
    max-height: 80vh;
`;

export const Title = styled.h2`
    font-weight: 500;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-right: 1rem;

    >button[type='submit']{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        padding: .8rem 1.8rem;
        border-radius: 0.3rem;
        color: white;
        background: var(--green);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;

    &:hover{
        box-shadow: 0px 15px 20px rgba(96, 213, 43, 0.3);
        transform: translateY(-3px);
    }
    }
`;

export const MainDeliveryContainer = styled.div`
    margin-bottom: 1rem;
`;

export const Select = styled.select`
    display: flex;
    padding: 0.8rem;
    width: 95%;
    border-radius: 0.3rem;
    transition: .4s;
    border: 0;
    background: var(--base-300);
    font-weight: 700;
    color: var(--base);

    &::selection{
        background: white;
    }

    &:hover{
        background: var(--base-400);
    }
`;

export const File = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

export const InputContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 1rem;

    >label{
        font-size: 1rem;
        margin-bottom: 0.3rem;
    }
    >input{
        border-radius: 0.3rem;
        padding: 0.8rem;
        width: 100%;
        transition: .4s;
        border: 0;
        background: var(--base-300);
    }
    &.social{
        width: 50%;
        margin-right: 1rem;
    }
`;

export const FileContainer = styled.div`
    margin-bottom: 1rem;
    >label{
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        font-size: 14px;
        padding: 10px 12px;
        background-color: #4245a8;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }
    >span{
        margin-left: 1rem;
        font-size: 0.9rem;
        color: var(--base);
    }
`;

export const SelectContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 55%;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContainerPlaces = styled.div`
    display: grid;
    grid-template-columns: .3fr 1fr 1fr 3fr 1fr .1fr;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 1rem;
    border-radius: 0.3rem;
    padding: 0.3rem;

    &:nth-child(2n){
        background: var(--base-300);
    }
`;

export const Places = styled.div`
    display: flex;
    max-height: 30vh;
    flex-direction: column;
    overflow-y: scroll;
`;

export const Loader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;


export const TextPlaces = styled.p`
    font-size: .9rem;
`;

export const ButtonPlus = styled.button`
    background: #47A1E2;
    border: 0;
    margin: .5rem 0 0 1rem;
    color: #fff;
    padding: 1rem 1rem;
    border-radius: 0.3rem;
`;

export const ButtonRemove = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: 1px solid red;
    margin: 0 0 0 1rem;
    color: red;
    border-radius: 0.3rem;
`;