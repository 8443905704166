import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    
    :root{
        --primary: #070D33;
        --secondary: #333;
        --green: #7dba00;
        --green-300: #9CCA22;
        --red: #E24747;
        --base: #757575;
        --base-100: #f0f0f0;
        --base-300: #e8e8e8;
        --base-400: #d9d9d9;
        --background: #ffffff;
    }
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: none;
    }
    html{
        @media (max-width: 1080px){
            font-size: 93.75%;
        }
        @media (max-width: 720px){
            font-size: 87.5%;
        }
        scroll-behavior: smooth;
    }
    body{
        background: var(--background);
        -webkit-font-smoothing: antialiased;
    }
    ::selection{
        color: #fff;
        background: #1f1f1f;
    }
    body, input, textarea, button, select{
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color: var(--secondary);
    }
    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 700;
    }
    p{
        font-size: 1rem;
        font-weight:300;
        color: var(--base);
    }
    
    a {
      text-decoration: none;
      color: var(--primary);
    }
    button{
        cursor: pointer;
    }
    
    [disabled],
    .disabled{
        opacity: 0.6;
        cursor: not-allowed;
    }

    .react-modal-overlay{
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items:center;
    }

    .react-modal-content{
        width: 100%;
        max-width: 680px;
        /* max-width: 576px; */
        background: var(--background);
        padding: 3rem;
        position: relative;
        border-radius: 0.25rem;
    }

    .react-modal-close{
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        border: 0;
        background: transparent;
        transition: .3s;

        &:hover{
            filter: brightness(0.8)
        }
    }


    .lds-ellipsis {
      display: inline-block;
      position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--primary);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
`;
