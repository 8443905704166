import styled from 'styled-components';

export const Container = styled.div`
    width: 10rem;
    height: 13rem;
    >img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 1rem;
    }

    >h3{
        width: 100%;
        text-align: center;
    }

    >button{
        display: flex;
        width: 100%;
        flex-direction: column;
        border: 0;
        border-radius: 1rem;
        z-index: 10;

        &:hover{
            opacity: .8;
        }

        &.danger{
            background: var(--red);
            width: 100%;
            border-radius: 0.3rem;
            padding: 0.5rem 1rem;
            color: white;
        }

    }
`;
