import { useEffect, useState } from "react"
import { PartnersType } from "../../@types/globalTypes";
import { CgChevronDown } from 'react-icons/cg';
import { usePartners } from "../../context/PartnersContext";
import brazilImg from '../../assets/brazil.svg'
import markerImg from '../../assets/marker.svg'
import {
    ArrowContainer,
    Banner,
    Body,
    Card,
    CardLogo,
    CardText,
    Container,
    ContainerIcon,
    ContentHeader,
    Footer,
    Header,
    IconSelect,
    Intagram,
    LinkFooter,
    Marker,
    Select,
    SelectContainer
} from './styles'

export function Home() {
    const { listPartners, banner } = usePartners();
    const listStates = listPartners.filter((elem, index, self) =>
        index === self.findIndex((t) => (
            t.state === elem.state
        ))
    );
    const statesOrderned = listStates.sort((a, b) => (a.state > b.state) ? 1 : ((b.state > a.state) ? -1 : 0))
    const [stateSelected, setStateSelected] = useState<string>('');
    const [citySelected, setCitySelected] = useState<string>('');
    const [cityFiltered, setCityFiltered] = useState<PartnersType[]>([]);
    const [partnersFiltered, setPartnersFiltered] = useState<PartnersType[]>([]);
    const [mainDelivery, setMainDelivery] = useState<PartnersType[]>([]);

    // Filtra as cidades por estado
    useEffect(() => {
        if (stateSelected) {
            const filteredActive = listPartners.filter(x => x.isActive)
            const filtred = filteredActive.filter(x => x.uf === stateSelected)
            const CityOrderned = filtred.sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0))
            setCityFiltered(CityOrderned.filter((elem, index, self) =>
                index === self.findIndex((t) => (
                    t.city === elem.city
                ))
            ))
            setCitySelected('');
        }
    }, [stateSelected, listPartners])

    // Filtra os parceiros por cidade
    useEffect(() => {
        if (citySelected) {
            const filteredActive = listPartners.filter(x => x.isActive)
            const filtered = filteredActive.filter(x => x.city === citySelected);
            const orderFiltered = filtered.sort((a, b) => (a.partner.name > b.partner.name) ? 1 : ((b.partner.name > a.partner.name) ? -1 : 0))
            const orderDeliveryMain = orderFiltered.sort((a, b) => (a.isMainDelivery < b.isMainDelivery) ? 1 : ((b.isMainDelivery < a.isMainDelivery) ? -1 : 0))
            setPartnersFiltered(orderDeliveryMain)
            window.location.href = `${window.location.origin}#delivery`
        }
    }, [citySelected, listPartners])

    useEffect(() => {
        setMainDelivery(partnersFiltered.filter(x => x.partner.addressLink))
    }, [partnersFiltered]);

    return (
        <Container>
            <Header>
                <ContentHeader>
                    <Banner src={banner} />
                    <p>Selecione seu ESTADO e sua CIDADE que mostraremos os deliveries perto de você!</p>
                    <SelectContainer>
                        <IconSelect>
                            <img src={brazilImg} alt="Estado" />
                        </IconSelect>
                        <Select name="states" defaultValue='' value={stateSelected} onChange={(e) => setStateSelected(e.target.value)}>
                            <option value='' disabled>Estado</option>
                            {statesOrderned.map(item => (
                                <option key={item.uf} value={item.uf}>{item.state}</option>
                            ))}
                        </Select>
                        <ArrowContainer>
                            <CgChevronDown />
                        </ArrowContainer>
                    </SelectContainer>
                    <SelectContainer>
                        <IconSelect>
                            <img src={markerImg} alt="Cidade" />
                        </IconSelect>
                        <Select name="city" defaultValue='' value={citySelected} onChange={(e) => setCitySelected(e.target.value)}>
                            <option value='' disabled>Cidade</option>
                            {cityFiltered.map(item => (
                                <option key={item.city} value={item.city}>{item.city}</option>
                            ))}
                        </Select>
                        <ArrowContainer>
                            <CgChevronDown />
                        </ArrowContainer>
                    </SelectContainer>
                </ContentHeader>
            </Header>
            <br />
            <Body id="delivery">
                {partnersFiltered.map((item) => (
                    <a href={item.partner.link} key={item.uid} target="_blank" rel="noreferrer">
                        <Card>
                            <CardLogo src={item.partner.logo} />
                            <CardText>{item.partner.name}</CardText>
                        </Card>
                    </a>
                ))}
            </Body>
            <Footer>
                {Boolean(mainDelivery.length) && (
                    <>
                        <LinkFooter target="_blank" rel="noreferer" href={`https://www.instagram.com/${mainDelivery[0].partner.instagram}`}>
                            <ContainerIcon>
                                <Intagram />
                            </ContainerIcon>
                            {mainDelivery[0].partner.instagram}
                        </LinkFooter>
                        <LinkFooter target="_blank" rel="noreferer" href={mainDelivery[0].partner.addressLink}>
                            <ContainerIcon>
                                <Marker />
                            </ContainerIcon>
                            Navegue até a loja
                        </LinkFooter>
                    </>
                )}
            </Footer>
        </Container>
    )
}
