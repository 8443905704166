import styled from 'styled-components';
import { FaInstagram, FaMapMarkerAlt } from 'react-icons/fa'

export const Container = styled.div`
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.header`
    display: flex;
    width: 100%;
    padding: 2rem;
    flex-direction: column;
    background: var(--primary);
    background-size: cover;
    justify-content: center;
    align-items: center;
`;

export const Body = styled.div`
    width: 90%;
    max-width: 760px;
    margin: 0 auto;
    padding: 2rem;
    padding-bottom: 10rem;
`;

export const Footer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    position: fixed;
    bottom: 0;
    align-self: center;
    align-items: center;
    max-width: 760px;
    width: 100%;
    z-index: 99;
    padding: 1rem;
    background: var(--background);
`;

export const Intagram = styled(FaInstagram)`
    font-size: 20px;
    color: #f5f5f5;
`;

export const Marker = styled(FaMapMarkerAlt)`
    font-size: 20px;
    color: #f5f5f5;
`;

export const ContainerIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary);
    padding: .5rem;
    border-radius: 5rem;
    margin-right: .8rem;
`;

export const LinkFooter = styled.a`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 1.1rem;
`;

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 760px;
    margin: 0 auto;
    
    > p {
        color: #fff;
        font-size: .9rem;
        margin-top: 1rem;
        text-align: center;
        font-weight: 400;
        padding: 1rem 0;
    }
`;

export const Banner = styled.img`
    width: 100%;
    border-radius: 1rem;
    object-fit: contain;
`;


export const SelectContainer = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    position: relative;
`;

export const IconSelect = styled.span`
    position: absolute;
    top: .3rem;
    bottom: 0.3rem;
    left: .3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary);
    border-radius: 5rem;
    width: 2.7rem;
    height: 2.7rem;
    pointer-events: none;

    >img{
        width: 70%;
        height: 70%;
    }
`;

export const ArrowContainer = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--green-300);
    color: white;
    font-size: 2rem;
    padding-right: 0.3rem;
    width: 4rem;
    height: 100%;
    border-radius: 0 2rem 2rem 0;
    pointer-events: none;
`;

export const Select = styled.select`
    border: 0;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 1rem 4rem;
    border-radius: 2rem;
    background: white;
    appearance: none;
    color: var(--base);
    box-shadow: 0 10px 25px rgba(0,0,0,0.1);
`;

export const Card = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: white;
    border: 3px solid var(--primary);
    box-shadow: 0 10px 25px rgba(0,0,0,0.2);
    margin-bottom: 1.5rem;
    border-radius: 5rem;
`;

export const CardLogo = styled.img`
    width: 3.5rem;
    height: 3.5rem;
    object-fit: contain;
    border-radius: 5rem;
    padding: .3rem;
`;

export const CardText = styled.div`
    margin-left: 1rem;
    font-size: 1.1rem;
    color: var(--primary);
    font-weight: 700;
`;