import Modal from 'react-modal';
import { IoCloseOutline, IoRemove } from 'react-icons/io5';
import db from '../../services/db.json';
import React, { FormEvent, useEffect, useState } from 'react';
import { firestore, storage } from '../../services/firebase';
import { toast } from 'react-toastify'
import { PlacesType } from '../../@types/globalTypes';
import { usePartners } from '../../context/PartnersContext';
import { FaInstagram } from 'react-icons/fa';
import {
    Container,
    Title,
    Form,
    Select,
    File,
    FileContainer,
    InputContainer,
    SelectContainer,
    Row,
    ButtonPlus,
    ContainerPlaces,
    TextPlaces,
    ButtonRemove,
    Places,
    Loader,
    MainDeliveryContainer
} from './styles';


type Props = {
    isOpen: boolean;
    onRequestClose: () => void;
    partnerName: string;
};

Modal.setAppElement('#root')

export function EditPartner({ isOpen, onRequestClose, partnerName }: Props) {
    const [cityFilted, setCityFilted] = useState(db.cidades);
    const [stateSelected, setStateSelected] = useState<string>('');
    const [citySelected, setCitySelected] = useState<string>('');
    const [namePartner, setNamePartner] = useState<string>('');
    const [instagram, setInstagram] = useState<string>('');
    const [addressLink, setAddressLink] = useState<string>('');
    const [link, setLink] = useState<string>('');
    const [logo, setLogo] = useState<string>('');
    const [places, setPlaces] = useState<PlacesType[]>([]);
    const [placesOld, setPlacesOld] = useState<PlacesType[]>([]);
    const [file, setFile] = useState<File>({} as File);
    const [loading, setLoading] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isMainDelivery, setIsMainDelivery] = useState<boolean>(false);
    const { listPartners, onIsRender } = usePartners();

    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        const filesList = e.target.files;
        if (!filesList) return;
        setFile(filesList[0])
    }

    function submitWithFile() {
        const ref = storage.ref(`/images/${file.name}`);
        const uploadTask = ref.put(file);
        uploadTask.on("state_changed", console.log, console.error, () => {
            ref.getDownloadURL()
                .then((url) => {
                    setFile({} as File);
                    places.forEach(async (element, index) => {
                        if (Boolean(placesOld.find(x => x.uid === element.uid))) {
                            await firestore.collection('places').doc(element.uid).update({
                                state: element.state,
                                uf: element.uf,
                                city: element.city,
                                isMainDelivery,
                                partner: {
                                    name: namePartner,
                                    logo: url,
                                    link: element.link,
                                    instagram: element.instagram,
                                    addressLink: element.addressLink,
                                }
                            }).then(() => {
                                if ((index + 1) === places.length) {
                                    toast.success('Dados alterados com sucesso');
                                    onRequestClose()
                                    setLoading(false);
                                    onIsRender();
                                }
                            }).catch(() => {
                                toast.error('Houve um erro ao registar o parceiro, por favor tente novamente');
                                setLoading(false);
                            })
                        } else {
                            await firestore.collection('places').add({
                                state: element.state,
                                uf: element.uf,
                                city: element.city,
                                isMainDelivery,
                                partner: {
                                    name: namePartner,
                                    logo: url,
                                    link: element.link,
                                    instagram: element.instagram,
                                    addressLink: element.addressLink,
                                }
                            }).then(() => {
                                if ((index + 1) === places.length) {
                                    toast.success('Dados alterados com sucesso');
                                    onRequestClose()
                                    setLoading(false);
                                    onIsRender();
                                }
                            }).catch(() => {
                                toast.error('Houve um erro ao registar o parceiro, por favor tente novamente');
                                setLoading(false);
                            })
                        }
                    })
                });
        });
    }

    function submitWithoutFile() {
        places.forEach(async (element, index) => {
            if (Boolean(placesOld.find(x => x.uid === element.uid))) {
                await firestore.collection('places').doc(element.uid).update({
                    state: element.state,
                    uf: element.uf,
                    city: element.city,
                    isMainDelivery,
                    partner: {
                        name: namePartner,
                        logo,
                        link: element.link,
                        instagram: element.instagram,
                        addressLink: element.addressLink,
                    }
                }).then(() => {
                    if ((index + 1) === places.length) {
                        toast.success('Dados alterados com sucesso');
                        onRequestClose()
                        setLoading(false);
                        onIsRender();
                    }
                }).catch(() => {
                    toast.error('Houve um erro ao registar o parceiro, por favor tente novamente');
                    setLoading(false);
                })
            } else {
                await firestore.collection('places').add({
                    state: element.state,
                    uf: element.uf,
                    city: element.city,
                    isMainDelivery,
                    partner: {
                        name: namePartner,
                        logo,
                        link: element.link,
                        instagram: element.instagram,
                        addressLink: element.addressLink,
                    }
                }).then(() => {
                    if ((index + 1) === places.length) {
                        toast.success('Dados alterados com sucesso');
                        onRequestClose()
                        setLoading(false);
                        onIsRender();
                    }
                }).catch(() => {
                    toast.error('Houve um erro ao registar o parceiro, por favor tente novamente');
                    setLoading(false);
                })
            }
        });
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        if (!places.length) return toast.info('Adicione os locais em que o parceiro se encontra')
        if (file.name) {
            setLoading(true);
            return submitWithFile();
        }
        if (logo) {
            setLoading(true);
            return submitWithoutFile()
        }
        else {
            alert('Você precisa selecionar uma imagem para logo');
        }
    }

    function handleAddPlaces() {
        if (!stateSelected && !citySelected) return toast.info('Estado e cidade são campos obrigatórios')
        const repeated = places.find(x => x.city === citySelected);
        if (repeated) return;

        const stateName = db.estados.find(x => x.id === stateSelected)?.estado || ''

        setPlaces(places => [...places, { state: stateName, uf: stateSelected, city: citySelected, link, instagram, addressLink }])
        setAddressLink('');
        setInstagram('');
    }

    async function handleDeletePlaces(e: React.MouseEvent<HTMLElement>, item: PlacesType) {
        var result = window.confirm("Tem certeza que deseja deletar esse local ? Caso confirme deletaremos agora mesmo!",);
        if (result) {
            await firestore.collection('places').doc(item.uid).delete().then(() => {
                toast.success('Local deletado com sucesso')
                setPlacesOld(places.filter(x => x !== item));
                setPlaces(places => places.filter(x => x !== item));
                onIsRender();
            })
        }
        else {
            e.preventDefault();
        }
    }

    useEffect(() => {
        if (stateSelected) {
            setCityFilted(db.cidades.filter(x => x.estadoId === stateSelected))
        }
    }, [stateSelected])

    useEffect(() => {
        setNamePartner('')
        setPlaces([])
        setFile({} as File);

    }, [onRequestClose])

    useEffect(() => {
        const list = listPartners.filter(x => x.partner.name === partnerName);
        list.forEach((element) => {
            setPlaces(places => [...places, {
                state: element.state,
                uf: element.uf,
                uid: element.uid,
                city: element.city,
                link: element.partner.link,
                instagram: element.partner.instagram,
                addressLink: element.partner.addressLink
            }]);
            setPlacesOld(places => [...places, {
                state: element.state,
                uf: element.uf,
                uid: element.uid,
                city: element.city,
                link: element.partner.link,
                instagram: element.partner.instagram,
                addressLink: element.partner.addressLink
            }]);
        })
        if (list[0]) {
            setIsMainDelivery(list[0].isMainDelivery || false);
            setNamePartner(list[0].partner.name || '');
            setLogo(list[0].partner.logo || '')
            setIsActive(list[0].isActive)
        }

    }, [listPartners, partnerName, onRequestClose])


    return (
        <Modal
            isOpen={isOpen}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button type="button" disabled={loading} onClick={onRequestClose} className="react-modal-close">
                <IoCloseOutline size="1.5rem" />
            </button>
            <Container>
                {loading
                    ? (
                        <Loader>
                            <h2>Atualizando todos parceiros...</h2>
                            <p>Por favor, aguarde</p>
                            <div className="lds-ellipsis"><div></div><div></div><div></div></div>
                        </Loader>
                    )
                    : <>
                        <Title>{namePartner}</Title>
                        <Form onSubmit={handleSubmit}>
                            <h4>Parceiro {isActive ? 'Habilitado': 'Desabilitado'}</h4>
                            {logo ? (
                                <>
                                    <img src={logo} alt="" />
                                    <button className='remove' onClick={() => setLogo('')} >Remover logo</button>
                                </>
                            ) : (
                                <FileContainer>
                                    <File type="file" accept=".png" id="file-input" onChange={handleFileChange} />
                                    <label htmlFor="file-input">Upload Logo</label>
                                    <span>{file?.name}</span>
                                    <p>Recomendado: 150x150</p>
                                </FileContainer>
                            )}
                            <InputContainer>
                                <label htmlFor="">Parceiro</label>
                                <input type="text" value={namePartner} onChange={(e) => setNamePartner(e.target.value)} placeholder="Digite o nome do parceiro" />
                            </InputContainer>
                            <MainDeliveryContainer>
                                <input type="checkbox" checked={isMainDelivery} onChange={() => setIsMainDelivery(x => !x)} id="isMainDelivery" />
                                <label htmlFor="isMainDelivery"> É o delivery principal ?</label>
                            </MainDeliveryContainer>
                            {isMainDelivery && (
                                <>
                                    <Row>
                                        <InputContainer className="social">
                                            <label htmlFor="">Instagram</label>
                                            <input type="text" value={instagram} onChange={(e) => setInstagram(e.target.value)} placeholder="Ex: acaiteriaaraguaina" />
                                        </InputContainer>
                                        <InputContainer>
                                            <label htmlFor="">Link do Endereço</label>
                                            <input type="text" value={addressLink} onChange={(e) => setAddressLink(e.target.value)} placeholder="Digite o link do endereço" />
                                        </InputContainer>
                                    </Row>
                                </>
                            )}
                            <Row>
                                <SelectContainer>
                                    <InputContainer>
                                        <label>Estado</label>
                                        <Select onChange={(e) => setStateSelected(e.target.value)}>
                                            <option value="">Selecione o estado</option>
                                            {db.estados.map((item) => (
                                                <option key={item.id} value={item.id}>{item.estado}</option>
                                            ))}
                                        </Select>
                                    </InputContainer>
                                    <InputContainer>
                                        <label>Cidade</label>
                                        <Select onChange={(e) => setCitySelected(e.target.value)}>
                                            <option value="">Selecione a cidade</option>
                                            {stateSelected && cityFilted.map((item, index) => (
                                                <option key={index} value={item.cidade}>{item.cidade}</option>
                                            ))}
                                        </Select>
                                    </InputContainer>
                                </SelectContainer>
                                <InputContainer>
                                    <label htmlFor="">Link</label>
                                    <input type="text" value={link} onChange={(e) => setLink(e.target.value)} placeholder="Ex. https://..." />
                                </InputContainer>
                                <ButtonPlus type="button" onClick={handleAddPlaces}>
                                    Add
                                </ButtonPlus>
                            </Row>
                            <Places>
                                {places.map((item, index) => (
                                    <>
                                        <ContainerPlaces key={index}>
                                            <TextPlaces><strong>{item.uf}</strong></TextPlaces>
                                            <TextPlaces>{item.city}</TextPlaces>
                                            <a href={item.addressLink} target="_blank" rel="noreferrer"><TextPlaces>Navegue até a loja</TextPlaces></a>
                                            <a href={`https://instagram.com/${item.instagram}`} target="_blank" rel="noreferrer">{item.instagram && <TextPlaces><FaInstagram /> {item.instagram}</TextPlaces>}</a>
                                            <a href={item.link} target="_blank" rel="noreferrer"><TextPlaces>{item.link}</TextPlaces></a>
                                            <ButtonRemove type="button" onClick={(e) => handleDeletePlaces(e, item)} title="Remove local">
                                                <IoRemove />
                                            </ButtonRemove>
                                        </ContainerPlaces>
                                    </>
                                ))}
                            </Places>
                            <button type="submit" disabled={loading}>{loading ? "Carregando..." : "Salvar Alterações"}</button>
                        </Form>
                    </>
                }
            </Container>
        </Modal>
    )
}