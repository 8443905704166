import Header from '../../components/Header';
import { IoAdd, IoImageOutline } from 'react-icons/io5'
import { NewPartner } from '../../components/NewPartner';
import BroImg from '../../assets/bro.svg';
import { useState } from 'react';
import { usePartners } from '../../context/PartnersContext';
import { CardPartnersAdmin } from '../../components/CardPartnersAdmin';
import {
    Container,
    Title,
    SubHeader,
    ButtonAdd,
    NotItems,
    NotItemsImage,
    NotItemsTitle,
    Content
} from './styles'
import { EditPartner } from '../../components/EditPartners';
import { BannerModal } from '../../components/BannerModal';
import { firestore } from '../../services/firebase';
import { toast } from 'react-toastify';

export function Dashboard() {
    const [newPartnerModal, setnewPartnerModal] = useState<boolean>(false);
    const [editPartnerModal, setEditPartnerModal] = useState<boolean>(false);
    const [bannerModal, setBannerModal] = useState<boolean>(false);
    const [partnerName, setPartnerName] = useState<string>('');
    const { listPartners, onIsRender } = usePartners();
    const listPartnersFiltered = listPartners.filter((elem, index, self) =>
        index === self.findIndex((t) => (
            t.partner.name === elem.partner.name
        ))
    );

    function handlePartner(name: string) {
        setPartnerName(name);
        setEditPartnerModal(true);
    }

    async function disablePartner(name: string) {
        const filtered = listPartners.filter(x => x.partner.name === name);
        filtered.forEach(async (element, index) => {
            await firestore.collection('places').doc(element.uid).update({
                isActive: !element.isActive,
            }).then(() => {
                if ((index + 1) === listPartners.length) {
                    toast.success(`Parceiro ${element.isActive ? 'desabilitado' : 'habilitado'}`);
                    onIsRender();
                }
            }).catch(() => {
                toast.error(`Houve um erro ao ${element.isActive ? 'desabilitar' : 'habilitado'}  o parceiro`);
            })
        })
    }

    return (
        <Container>
            <Header />
            <SubHeader>
                <Title>Parceiros</Title>
                <div>
                    <ButtonAdd onClick={() => setBannerModal(true)}>
                        <IoImageOutline size="1.5rem" />
                        BANNER
                    </ButtonAdd>
                    <ButtonAdd onClick={() => setnewPartnerModal(true)}>
                        <IoAdd size="1.5rem" />
                        ADICIONAR PARCEIRO
                    </ButtonAdd>
                </div>
            </SubHeader>
            {!listPartners.length && (
                <NotItems>
                    <NotItemsTitle>Adicione seu primeiro parceiro 💜</NotItemsTitle>
                    <NotItemsImage src={BroImg} />
                </NotItems>
            )}
            <Content>
                {listPartnersFiltered.map((item) => (
                    <CardPartnersAdmin key={item.uid} item={item} onDisable={() => disablePartner(item.partner.name)} onClick={() => handlePartner(item.partner.name)} />
                ))}
            </Content>
            <NewPartner isOpen={newPartnerModal} onRequestClose={() => setnewPartnerModal(false)} />
            <BannerModal isOpen={bannerModal} onRequestClose={() => setBannerModal(false)} />
            <EditPartner isOpen={editPartnerModal} partnerName={partnerName} onRequestClose={() => setEditPartnerModal(false)} />
        </Container>
    )
}
