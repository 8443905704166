import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PartnersType } from "../@types/globalTypes";
import { firestore } from '../services/firebase';

type PartnersContextProviderProps = {
    children: ReactNode;
}

type PartnersContextType = {
    listPartners: PartnersType[];
    banner: string;
    onIsRender: () => void;
}

const PartnersContext = createContext({} as PartnersContextType);

export function PartnersProvider({ children }: PartnersContextProviderProps) {
    const [listPartners, setListPartners] = useState<PartnersType[]>([]);
    const [banner, setBanner] = useState<string>('');
    const [isRender, setIsRender] = useState<boolean>(false);

    useEffect(() => {
        loadPartners();
        loadBanner();
    }, [isRender]);

    async function loadPartners() {
        try {
            const response = await firestore.collection('places').get();
            const data = response.docs.map(doc => ({ ...doc.data(), uid: doc.id }))
            setListPartners(data as PartnersType[])
        } catch {
            toast.error('Houve algum problema na listagem dos parceiros');
        }
    }

    function onIsRender() {
        setIsRender(x => !x);
    }

    async function loadBanner() {
        const response = await firestore.collection('banners').doc(process.env.REACT_APP_BANNER).get();
        const data = response.data() || {};
        if (!data.bannerLink) return;
        setBanner(data.bannerLink || '')
    }

    return (
        <PartnersContext.Provider value={{ listPartners, banner, onIsRender }}>
            {children}
        </PartnersContext.Provider>
    );
}
export function usePartners() {
    return useContext(PartnersContext);
}