import styled from "styled-components";

export const Container = styled.div`
  max-width: 1260px;
  height: 100vh;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    button {
      background: var(--primary);
      min-width: 20rem;
      border: 1px solid var(--primary);
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding: 0.8rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      border-radius: 0.3rem;
      transition: 0.3s;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
`;

export const Title = styled.div`
  margin-top: 2rem;
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
`;