import { ReactNode } from "react";
import { AuthProvider } from "./AuthContext";
import { PartnersProvider } from "./PartnersContext";

type Props = {
    children: ReactNode;
}

export function GlobalContextProvider({ children }: Props) {
    return (
        <>
            <AuthProvider>
                <PartnersProvider>
                    {children}
                </PartnersProvider>
            </AuthProvider>
        </>
    )
}
